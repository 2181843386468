<template>
  <Banner bg-image="red-bg.jpg" talk-img="about-wiichihew.png">
    <template v-slot:title> About Wiichihew </template>
    <template v-slot:subtitle
      >Welcome to Wiichihew. Wiichihew means “support” in the Michif language.
    </template>
    <template v-slot:description>
      Learn how Miskanawah is working tirelessly to end this crisis among our
      young people.</template
    >
  </Banner>

  <section class="pb-10">
    <div class="max-w-6xl px-4 mx-auto sm:px-6">
      <div class="mb-6 text-2xl font-bold call-text md:text-3xl text-blue">
        Designed by our community for our community
      </div>
      <p class="my-6">
        Wiichihew—a suicide prevention and emotional well-being web app for
        Indigenous youth—was launched by Miskanawah in 2021 to respond to a
        public health crisis impacting Indigenous youth.
      </p>
      <p>
        Wiichihew has been specifically designed with input from the Healing
        Lodge clinicians at Miskanawah, Indigenous youth, elders, and
        individuals with lived experience of suicide. It is funded by Indigenous
        Support Fund. This suicide prevention web app is packed full of useful
        information and tools to help Indigenous youth stay safe in crisis. Use
        this web app if you are having thoughts of suicide or if you are
        concerned about someone else who may be considering suicide.
      </p>
    </div>
  </section>
  <section class="pt-10 gradient-blue md:pt-14">
    <div class="max-w-5xl px-4 mx-auto sm:px-6">
      <div
        class="mb-6 text-2xl font-bold text-center call-text md:text-3xl text-blue"
      >
        Providing tools and resources for teens and young adults
      </div>
      <div class="grid md:grid-cols-2 gap-14">
        <div class="mb-10 md:mb-0">
          <p class="mb-10">
            The aim of Wiichihew is to provide a platform for Indigenous young
            people to access and utilize useful information and tools to help
            them work through challenging thoughts and feelings. Wiichihew
            equips Indigenous teens and young adults with the skills and
            knowledge to help themselves and each other.
          </p>
          <GreenBtn text="Explore Resources" name="ToolsAndResources" />
        </div>
        <div>
          <ul class="about-ul">
            <li>Breathing strategies</li>
            <li>Mindfulness</li>
            <li>Grounding strategies</li>
            <li>Warning signs</li>
            <li>Indigenous ways of well-being</li>
            <li>Ways to support someone considering suicide</li>
          </ul>
        </div>
      </div>
    </div>
  </section>
  <section class="py-14">
    <div class="max-w-6xl px-4 mx-auto sm:px-6">
      <div class="mb-6 text-2xl font-bold call-text md:text-3xl text-blue">
        What we know about suicide in our community
      </div>
      <p>
        Every year many young people try or contemplate suicide, harm themselves
        or suffer alone, afraid to speak openly about how they are feeling.
        Indigenous young people are more than four times more likely to attempt
        suicide than their peers, and suicide remains a leading cause of death
        among all young people in Canada.
      </p>
    </div>
  </section>
  <section class="pb-14">
    <div class="max-w-5xl px-4 mx-auto sm:px-6">
      <div class="grid md:grid-cols-2 gap-14">
        <div class="mb-10 md:border-r-2 md:mb-0 md:border-white-100">
          <div class="mb-6 text-2xl call-text md:text-3xl text-blue">
            Our vision
          </div>
          <p class="mb-8">
            Our vision is for indigenous youth to have the structures that
            supports emotional health, reduces risks and the resources that
            equip them to navigate mental health challenges—manage suicidal
            thoughts.
          </p>
          <div class="mb-6 text-2xl call-text md:text-3xl text-blue">
            We envision
          </div>
          <p>We envision a healthy future for Indigenous youth.</p>
        </div>
        <div>
          <div>
            <img src="../assets/images/vision.png" class="mx-auto md:ml-auto" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Banner from "@/components/Banner.vue";
import GreenBtn from "@/components/GreenBtn.vue";
export default {
  name: "AboutWiichihew",
  components: {
    Banner,
    GreenBtn,
  },
};
</script>

<style scoped>
.gradient-blue {
  background: linear-gradient(to bottom, #f2f5fd, #fff);
}
ul.about-ul li {
  padding: 8px 0 8px 50px;
  border-bottom: 1px solid #e5e5e5;
  position: relative;
}
ul.about-ul li::after {
  content: "";
  background: #22b46e;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  position: absolute;
  left: 15px;
  top: 15px;
}
</style>
